import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import moment from "moment-timezone";
import { PREFIX_ARRAY, MONTH_ARRAY } from "../../../constants";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "../../utils/PhoneInput";


const EditRecord = forwardRef(({ recordDetail, formId }: any, ref:any) => {
  const [requiredMessage, setRequiredMessage]: any = useState({});
  const [localPhoneNumber, setLocalPhoneNumber] = useState("");
  const [errMessage, setErrMessage]: any = useState({});
  const [listOfCountry, setListOfCountry] = useState([]);
  const [formData, setFormData]: any = useState({});
  const axios = useAxios();
  const phoneInputRef = useRef<any>(null);

  const handleBlur = useCallback(
    (fieldName: string) => {
      if (phoneInputRef.current) {
        const phoneNumber = phoneInputRef.current.getPhoneNumber();
        setFormData((prevState: any) => ({
          ...prevState,
          [fieldName]: phoneNumber,
        }));
      }
    },
    [setFormData]
  );
  
  const change = (value: string) => {
    setLocalPhoneNumber(value);
  };

  const getCountryList = async () => {
    let countryList: any = [];
    const response = await axios.get(
      `https://surveyjs.io/api/CountriesExample`
    );
    countryList = response;
    const originalArray = countryList.map((country: any) => ({
      Key: country.name,
      Value: country.name,
    }));
    setListOfCountry(originalArray);
  };

  const downloadFile = async (file: any) => {
    let { data }: any = await axios.get("common/signed-download-url", {
      params: { path: file.Path },
    });
    let link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("hidden", "true");
    link.setAttribute("download", "");
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  useEffect(() => {
    const newFormData: any = {};
    getCountryList();
    const formatField = (field: string, value: any, type?: string, format?: string) => {
        switch (type) {
            case "boolean":
            case "privacypolicy":
            case "termsconditions":
                return { Key: !!value, Value: value ? "Yes" : "No" };
            case "tagbox":
            case "checkbox":
                return Array.isArray(value) ? value.map((item: any) => ({ Key: item, Value: item })) : [];
            case "fullname":
                return value.prefixDropdown
                    ? {
                          prefixDropdown: value.prefixDropdown
                              ? value.prefixDropdown
                              : null,
                          firstName: value.firstName || "",
                          middleName: value.middleName || "",
                          lastName: value.lastName || "",
                      }
                    : {
                          prefixText: value.prefixText || null,
                          firstName: value.firstName || "",
                          middleName: value.middleName || "",
                          lastName: value.lastName || "",
                      };
            case "dropdown":
                return { Key: value, Value: value };
            default:
                if (field.includes(".country")) {
                    return typeof value === "object" ? "" : { Key: value, Value: value };
                } else if (field.includes(".city") || field.includes(".state") || field.includes(".postalCode")) {
                    return typeof value === "object" ? "" : value;
                }
                return value;
        }
    };
    recordDetail.forEach((d: any) => {
        const { type, field, inputType, dateFormat, monthOptions } = d;
        if (type === "monthfield") {
          if(monthOptions){
            d.monthOptions = monthOptions.map((option: any) => ({
                text: typeof option === "string" ? option : option.text,
                value: typeof option === "string" ? option : option.text,
            }));   
          }
          newFormData[field] = { value: d[field], text: d[field] };         
        }else if (type === "phonenumber") {
            newFormData[field] = d[field];
        }else if (type === "datepicker") { 
          if (dateFormat === "YYYY-MM-DD") newFormData[field] = d[field] ? moment(d[field]).format(dateFormat) : "";
          else if(dateFormat === "DD-MM-YYYY") newFormData[field] = d[field];   
          else newFormData[field] = d[field] ? moment(d[field]).format("MM-DD-YYYY") : "";      
        }else if(inputType === "date"){
          newFormData[field] = d[field] ? d[field] : "" ;
        }else if (inputType === "datetime-local") {
          newFormData[field] = d[field] ;
        }else {
          newFormData[field] = formatField(field, d[field], type || inputType, dateFormat);
        }
    });
    setFormData(newFormData);
}, [recordDetail]);


  const handleSelectChange = (selectedOption: any, field: string, d: any) => {
    if (Array.isArray(selectedOption)) {
      const selectedValues = selectedOption.map((option: any) => ({
        Key: option.Key,
        Value: option.Value,
      }));
  
      setFormData((prevState: any) => ({
        ...prevState,
        [field]: selectedValues,
      }));
    } else {
      if (field.includes('.prefixDropdown')) {
        setFormData((prevState: any) => ({
          ...prevState,
          [d.field]: {
            ...prevState[d.field], // Preserve other fields in the object
            prefixDropdown: selectedOption ? selectedOption.value : null,
          },
        }));
      } else {
        setFormData((prevState: any) => ({
          ...prevState,
          [d.field]: selectedOption
        }));
      }
    }
  };

  useImperativeHandle(ref, () => ({
    saveFormData: async () => {
        let requiredErrorCount = 0;
        const editFormData: any = {};
        let recordId = null;
        const inputTypes = ["week", "month", "time", "color", "email", "number", "range", "url", "tel", "password"];
        console.log(requiredErrorCount,"requireStart");
        recordDetail.forEach((d: any) => { 
          recordId = d._id;
          switch (d.type) {
            case "address":
              if ((d.isRequired || d.streetAddressLine1IsRequired) && !formData[d.field].streetAddressLine1) {
                setRequiredMessage((prev: any) => ({
                  ...prev,
                  [`${d.field}.streetAddressLine1`]:
                    "Street Address Line 1 is required",
                }));
                requiredErrorCount += 1; 
                console.log(requiredErrorCount,"address1",formData[d.field]);
              }
              if ((d.isRequired || d.streetAddressLine2IsRequired) && !formData[d.field].streetAddressLine2) {
                setRequiredMessage((prev: any) => ({
                  ...prev,
                  [`${d.field}.streetAddressLine2`]:
                    "Street Address Line 2 is required",
                }));
                requiredErrorCount += 1; 
                console.log(requiredErrorCount,"address2",formData[d.field]);
              }
              if (!d.field.includes(".city") && !d.field.includes(".country") && !d.field.includes(".state") && !d.field.includes(".postalCode")) {
                const { streetAddressLine1, streetAddressLine2 } = formData[d.field];
                const searchValue = [streetAddressLine1, streetAddressLine2]
                  .filter(Boolean)
                  .join(" ");
                editFormData[d.field] = formData[d.field];
                editFormData[`${d.field}_addressLine`] = searchValue.trim();
              }
              break;
            case "fullname":
              if (d.prefixFieldType) {
                if ((d.isRequired || d.prefixRequired) && !formData[d.field].prefixText) {
                  setRequiredMessage((prev: any) => ({
                    ...prev,
                    [`${d.field}.prefixText`]: "Prefix is required",
                  }));
                  requiredErrorCount += 1; 
                  console.log(requiredErrorCount,"prefixText",formData[d.field]);
                }
              } else if (formData[d.field].hasOwnProperty("prefixDropdown")) {
                if ((d.isRequired || d.prefixRequired) && !formData[d.field].prefixDropdown) {
                  setRequiredMessage((prev: any) => ({
                    ...prev,
                    [`${d.field}.prefixDropdown`]: "Prefix is required",
                  }));
                  requiredErrorCount += 1; 
                  console.log(requiredErrorCount,"dropdown",formData[d.field]);
                }
              }
              if ((d.isRequired || d.firstNameRequired) && !formData[d.field].firstName) {
                setRequiredMessage((prev: any) => ({
                  ...prev,
                  [`${d.field}.firstName`]: "First Name is required",
                }));
                requiredErrorCount += 1; 
                console.log(requiredErrorCount,"firstname",formData[d.field]);
              }
              if ((d.isRequired || d.middleNameRequired) && !formData[d.field].middleName) {
                setRequiredMessage((prev: any) => ({
                  ...prev,
                  [`${d.field}.middleName`]: "Middle Name is required",
                }));
                requiredErrorCount += 1; 
                console.log(requiredErrorCount,"midlename",formData[d.field]);
              }
              if ((d.isRequired || d.lastNameRequired) && !formData[d.field].lastName) {
                setRequiredMessage((prev: any) => ({
                  ...prev,
                  [`${d.field}.lastName`]: "Last Name is required",
                }));
                requiredErrorCount += 1; 
                console.log(requiredErrorCount,"lastname",formData[d.field]);
              }
              const {
                prefixDropdown,
                prefixText,
                firstName,
                middleName,
                lastName,
              } = formData[d.field];
              const prefix = prefixDropdown ? prefixDropdown.text : prefixText;
              const fullName = [prefix, firstName, middleName, lastName]
                .filter(Boolean)
                .join(" ");
              editFormData[d.field] = formData[d.field];
              editFormData[`${d.field}_fullName`] = fullName.trim();
              const prefixValue = formData[d.field].prefixDropdown;
              if (formData[d.field].hasOwnProperty("prefixDropdown")) {
                formData[d.field] = {
                  prefixDropdown: prefixValue
                    ? prefixValue
                    : null,
                  firstName: formData[d.field].firstName || "",
                  middleName: formData[d.field].middleName || "",
                  lastName: formData[d.field].lastName || "",
                };
              }
              if (formData[d.field].hasOwnProperty("prefixText")) {
                formData[d.field] = {
                  prefixText: formData[d.field].prefixText ? formData[d.field].prefixText : null,
                  firstName: formData[d.field].firstName || "",
                  middleName: formData[d.field].middleName || "",
                  lastName: formData[d.field].lastName || "",
                };
              }
              break;
            case "phonenumber":
              if (d.isRequired && !formData[d.field]) {
                setRequiredMessage((prev: any) => ({
                  ...prev,
                  [d.field]: "This field is required",
                }));
                requiredErrorCount += 1; 
                console.log(requiredErrorCount,"phonereui",formData[d.field]);
              } else {
                const phoneNumber = formData[d.field]; 
                const countryCodeMatch = phoneNumber.match(/^\+(\d+)/);
                const countryCode = countryCodeMatch ? countryCodeMatch[1] : null;
                if (formData[d.field] && !window.intlTelInputUtils.isValidNumber(formData[d.field], countryCode)) {
                  setErrMessage((prev: any) => ({
                    ...prev,
                    [d.field]: "Please enter a valid number",
                  }));
                  requiredErrorCount += 1; 
                  console.log(requiredErrorCount,"invalidphone",formData[d.field]);
                } else {
                  editFormData[d.field] = formData[d.field];
                }
              }
              break;
            case "tagbox":
            case "checkbox":
              if (d.isRequired && formData[d.field].length === 0) {
                setRequiredMessage((prev: any) => ({
                  ...prev,
                  [d.field]: "This field is required",
                }));
                requiredErrorCount += 1; 
                console.log(requiredErrorCount,"checkbox,tagbox",formData[d.field]);
              } else {
                if (formData[d.field])
                  editFormData[d.field] = formData[d.field].map((obj: any) => obj.Value);
              }
              break;
            case "dropdown":
              if (d.isRequired && !formData[d.field]) {
                setRequiredMessage((prev: any) => ({
                  ...prev,
                  [d.field]: "This field is required",
                }));
                requiredErrorCount += 1; 
                console.log(requiredErrorCount,"dropdown",formData[d.field]);
              } else {
                editFormData[d.field] = formData[d.field] ? formData[d.field].Value : "";
              }
              break;
            case "monthfield":
              if (d.isRequired && !formData[d.field]) {
                setRequiredMessage((prev: any) => ({
                  ...prev,
                  [d.field]: "This field is required",
                }));
                requiredErrorCount += 1; 
                console.log(requiredErrorCount,"month",formData[d.field]);
              } else {
                editFormData[d.field] = formData[d.field] ? formData[d.field]?.text : "";

              }
              break;
            case "boolean":
              if (d.isRequired && !formData[d.field]) {
                setRequiredMessage((prev: any) => ({
                  ...prev,
                  [d.field]: "This field is required",
                }));
                requiredErrorCount += 1; 
                console.log(requiredErrorCount,"boolen",formData[d.field]);
              } else {
                editFormData[d.field] = formData[d.field] ? formData[d.field].Key : false;
              }
              break;
            case "emailfield":
              if (d.isRequired && !formData[d.field]) {
                setRequiredMessage((prev: any) => ({
                  ...prev,
                  [d.field]: "This field is required",
                }));
                requiredErrorCount += 1; 
                console.log(requiredErrorCount,"emailrequire",formData[d.field]);
              } else {
                const mailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                if (formData[d.field]) {
                  const mailValid = mailPattern.test(formData[d.field]);
                  if (mailValid) {
                    editFormData[d.field] = formData[d.field];
                  } else {
                    setRequiredMessage((prev: any) => ({
                      ...prev,
                      [d.field]: "Please enter a valid email",
                    }));
                    requiredErrorCount += 1; 
                    console.log(requiredErrorCount,"invalidemail",formData[d.field]);
                  }
                } else {
                  editFormData[d.field] = formData[d.field];
                }
              }
              break;
            case "text":
            case "comment":
              if (d.isRequired && !formData[d.field] && !d.inputType) {
                setRequiredMessage((prev: any) => ({
                  ...prev,
                  [d.field]: "This field is required",
                }));
                requiredErrorCount += 1; 
                console.log(requiredErrorCount,"textcommen",formData[d.field]);
              } else {
                editFormData[d.field] = formData[d.field] ? formData[d.field] : "";
              }
              break;
            default:
              if (d.type === "datepicker") {
                if (d.isRequired && !formData[d.field]) {
                  setRequiredMessage((prev: any) => ({
                    ...prev,
                    [d.field]: "This field is required",
                  }));
                  requiredErrorCount += 1;
                  console.log(requiredErrorCount,"datepciekt",formData[d.field]); 
              } else if(formData[d.field]) editFormData[d.field] = { [d.field]: formData[d.field], inputType: "datepicker" };
              } else if(d.inputType === "date"){
                if (d.isRequired && !formData[d.field]) {
                  setRequiredMessage((prev: any) => ({
                    ...prev,
                    [d.field]: "This field is required",
                  }));
                  requiredErrorCount += 1;
                  console.log(requiredErrorCount,"datepciekt",formData[d.field]); 
              } else if(formData[d.field]) editFormData[d.field] = { [d.field]: formData[d.field], inputType: "datepicker" };
              } else editFormData[d.field] = formData[d.field];
              break;
          }
          if (d.inputType === "datetime-local") {
            if (d.isRequired && !formData[d.field]) {
              setRequiredMessage((prev: any) => ({
                ...prev,
                [d.field]: "This field is required",
              }));
              requiredErrorCount += 1;
            }else{
              const dateTime = formData[d.field]
              editFormData[d.field] = {
                [d.field]: dateTime,
                inputType: "datetime-local",
              };
            }
          }

          if (inputTypes.includes(d.inputType)) {
            if (d.isRequired && !formData[d.field]) {
              setRequiredMessage((prev: any) => ({
                ...prev,
                [d.field]: "This field is required",
              }));
              requiredErrorCount += 1;
            } else{
                editFormData[d.field] = formData[d.field];
            }
          }
          
          if (d.field.includes("city")) {
            if ((d.isRequired || d.cityIsRequired) && !formData[d.field]) {
              setRequiredMessage((prev: any) => ({
                ...prev,
                [d.field]: "This field is required",
              }));
              requiredErrorCount += 1;
              console.log(requiredErrorCount,"city",formData[d.field]);
            } else {
              const addressKey = d.field.split(".city")[0];
              if (!editFormData[addressKey]) editFormData[addressKey] = {};
              editFormData[addressKey].city = formData[d.field];
              delete editFormData[d.field];
            }
          }
          if (d.field.includes("state")) {
            if ((d.isRequired || d.stateIsRequired) && !formData[d.field]) {
              setRequiredMessage((prev: any) => ({
                ...prev,
                [d.field]: "This field is required",
              }));
              requiredErrorCount += 1;
              console.log(requiredErrorCount,"state",formData[d.field]);
            } else {
              const addressKey = d.field.split(".state")[0];
              if (!editFormData[addressKey]) editFormData[addressKey] = {};
              editFormData[addressKey].state = formData[d.field];
              delete editFormData[d.field];
            }
          }
          if (d.field.includes("country")) {
            if ((d.isRequired || d.countryIsRequired) && !formData[d.field]) {
              setRequiredMessage((prev: any) => ({
                ...prev,
                [d.field]: "This field is required",
              }));
              requiredErrorCount += 1;
              console.log(requiredErrorCount,"country",formData[d.field]);
            } else {
              const addressKey = d.field.split(".country")[0];
              if (!editFormData[addressKey]) editFormData[addressKey] = {};
              editFormData[addressKey].country = formData[d.field].Value;
              delete editFormData[d.field];
            }
          }
          if (d.field.includes("postalCode")) {
            if ((d.isRequired || d.postalCodeIsRequired) && !formData[d.field]) {
              setRequiredMessage((prev: any) => ({
                ...prev,
                [d.field]: "This field is required",
              }));
              requiredErrorCount += 1;
              console.log(requiredErrorCount,"postalcode",formData[d.field]);
            } else {
              const addressKey = d.field.split(".postalCode")[0];
              if (!editFormData[addressKey]) editFormData[addressKey] = {};
              editFormData[addressKey].postalCode = formData[d.field];
              delete editFormData[d.field];
            }
          }
        });
        console.log(requiredErrorCount, "requiredEnd");
        if (requiredErrorCount < 1) {
            editFormData["_id"] = recordId;
            const response:any = await axios.post("forms/update-record", {
              formData:{...editFormData},
              formId: formId,
            });
            if (response.message === "success") {
              return true;
            } else {
              return false;
            }
        } else {
          return false;
        }
    }
  }));

  const removeErr = (fieldName?: any, id?: any) => {
    setRequiredMessage((prev: any) => {
        const updatedMessages = { ...prev };
        if (id) {
            const fields = [
                "prefixText",
                "prefixDropdown",
                "firstName",
                "middleName",
                "lastName",
                "streetAddressLine1",
                "streetAddressLine2",
            ];

            if (fields.includes(fieldName)) {
                updatedMessages[`${id}.${fieldName}`] = "";
            }
        } else {
            updatedMessages[fieldName] = "";
        }
        return updatedMessages;
    });
    setErrMessage((prev: any) => {
        const updatedMessages = { ...prev };
        if (!id) {
            updatedMessages[fieldName] = "";
        }
        return updatedMessages;
    });
    // if(!formData[fieldName])setErrorCount(0);
};

  const booleanOptions = [
    { Key: true, Value: 'Yes' },
    { Key: false, Value: 'No' },
  ];

  const renderFiles = (files:any) => {
    return files.map((file:any, index:any) => (
      <div key={index} className="ml-auto multi-action-button">
        {file.FileName}
        <code style={{ fontSize: '15px', color:"#bb2124", marginLeft:"5px"}}>
          {(parseInt(file.Size) / 1024).toFixed(2)} MB
        </code>
        <i
          onClick={() => downloadFile(file)}
          className="btn btn-sm far fa-download download-file p-1"
          data-toggle="tooltip"
          data-placement="top"
          title="Download"
          style={{marginLeft : "10px"}}
        />
      </div>
    ));
  };

  return (
    <div>
      {recordDetail.map((d: any, i: any) => (
        <div className="row" key={`${i}_details`}>
          {d.field !== "CreatedAt" && (
          <div className="col">
            {d.type === "fullname" && (
              <div className="bg_blue p-3">
                <h6 className="font-weight-bold text-lg">
                  {d.label}
                  {d.isRequired && (
                    <span className="mandatory" style={{ color: "red" }}>
                      *
                    </span>
                  )}
                </h6>
                {d.prefixFieldType && d.prefixVisible && (
                  <>
                    <h6 className="font-weight-bold mt-4">
                      Prefix{" "}
                      {d.prefixRequired && (
                        <span className="mandatory" style={{ color: "red" }}>
                          *
                        </span>
                      )}
                    </h6>
                    <input
                      type="text"
                      onFocus={() => removeErr("prefixText", d.field)}
                      value={formData[d.field]?.prefixText || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [d.field]: {
                            ...formData[d.field],
                            prefixText: e.target.value,
                          },
                        })
                      }
                      className="phone-form-control"
                      placeholder=""
                    />
                    <p
                      className="field-error font-weight-bold"
                      style={{ opacity: 0.6, color: "red" }}
                    >
                      {requiredMessage[d.field + ".prefixText"] || ""}
                    </p>
                  </>
                )}
                {!d.prefixFieldType && d.prefixVisible && (
                  <>
                    <h6 className="font-weight-bold mt-4">
                      Prefix{" "}
                      {d.prefixRequired && (
                        <span className="mandatory" style={{ color: "red" }}>
                          *
                        </span>
                      )}
                    </h6>
                    <Select
                      onFocus={() => removeErr("prefixDropdown", d.field)}
                      value={
                        (d.prefixChoices || PREFIX_ARRAY).find(
                          (option: any) => option.value === formData[d.field]?.prefixDropdown
                        ) || null
                      }
                      onChange={(selectedOption: any) => handleSelectChange(selectedOption, `${d.field}.prefixDropdown`, d)}
                      options={d.prefixChoices || PREFIX_ARRAY}
                      getOptionLabel={(option: any) => option.text}
                      getOptionValue={(option: any) => option.value}
                    />
                    <p
                      className="field-error font-weight-bold"
                      style={{ opacity: 0.6, color: "red" }}
                    >
                      {requiredMessage[d.field + ".prefixDropdown"] || ""}
                    </p>
                  </>
                )}
                <div>
                  <h6 className="font-weight-bold mt-4">
                    First Name{" "}
                    {d.firstNameRequired && (
                      <span className="mandatory" style={{ color: "red" }}>
                        *{" "}
                      </span>
                    )}
                  </h6>
                  <input
                    type="text"
                    onFocus={() => removeErr("firstName", d.field)}
                    value={formData[d.field]?.firstName || ""}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [d.field]: {
                          ...formData[d.field],
                          firstName: e.target.value,
                        },
                      })
                    }
                    className="phone-form-control"
                    placeholder=""
                  />
                  <p
                    className="field-error font-weight-bold"
                    style={{ opacity: 0.6, color: "red" }}
                  >
                    {requiredMessage[d.field + ".firstName"] || ""}
                  </p>
                </div>
                {d.middleNameVisible && (
                  <div>
                    <h6 className="font-weight-bold mt-4">
                      Middle Name{" "}
                      {d.middleNameRequired && (
                        <span className="mandatory" style={{ color: "red" }}>
                          *{" "}
                        </span>
                      )}
                    </h6>
                    <input
                      type="text"
                      onFocus={() => removeErr("middleName", d.field)}
                      value={formData[d.field]?.middleName || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [d.field]: {
                            ...formData[d.field],
                            middleName: e.target.value,
                          },
                        })
                      }
                      className="form-control"
                      placeholder=""
                    />
                    <p
                      className="field-error font-weight-bold"
                      style={{ opacity: 0.6, color: "red" }}
                    >
                      {requiredMessage[d.field + ".middleName"] || ""}
                    </p>
                  </div>
                )}
                <div>
                  <h6 className="font-weight-bold mt-4">
                    Last Name{" "}
                    {d.lastNameRequired && (
                      <span className="mandatory" style={{ color: "red" }}>
                        *{" "}
                      </span>
                    )}
                  </h6>
                  <input
                    type="text"
                    onFocus={() => removeErr("lastName", d.field)}
                    value={formData[d.field]?.lastName || ""}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [d.field]: {
                          ...formData[d.field],
                          lastName: e.target.value,
                        },
                      })
                    }
                    className="phone-form-control"
                    placeholder=""
                  />
                  <p
                    className="field-error font-weight-bold"
                    style={{ opacity: 0.6, color: "red" }}
                  >
                    {requiredMessage[d.field + ".lastName"] || ""}
                  </p>
                </div>
              </div>
            )}
            {d.type === 'address' && !d.field.includes('.city') && !d.field.includes('.country') && !d.field.includes('.state') && !d.field.includes('.postalCode') && (
            <div className="bg_blue p-3">
              <h6 className="font-weight-bold text-lg">
                {d.label}
                {d.isRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
              </h6>
              <div>
                <h6 className="font-weight-bold mt-4">
                  Address 1 {d.streetAddressLine1IsRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
                </h6>
                <input
                  type="text"
                  onFocus={() => removeErr('streetAddressLine1', d.field)}
                  value={formData[d.field]?.streetAddressLine1 || ''}
                  onChange={(e) => setFormData({
                    ...formData,
                    [d.field]: {
                      ...formData[d.field],
                      streetAddressLine1: e.target.value
                    }
                  })}
                  className="phone-form-control"
                  placeholder=""
                />
                <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: 'red' }}>
                  {requiredMessage[d.field + '.streetAddressLine1'] || ''}
                </p>
              </div>
              <div>
                <h6 className="font-weight-bold mt-4">
                  Address 2 {d.streetAddressLine2IsRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
                </h6>
                <input
                  type="text"
                  onFocus={() => removeErr('streetAddressLine2', d.field)}
                  value={formData[d.field]?.streetAddressLine2 || ''}
                  onChange={(e) => setFormData({
                    ...formData,
                    [d.field]: {
                      ...formData[d.field],
                      streetAddressLine2: e.target.value
                    }
                  })}
                  className="phone-form-control"
                  placeholder=""
                />
                <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: 'red' }}>
                  {requiredMessage[d.field + '.streetAddressLine2'] || ''}
                </p>
              </div>
            </div>
            )}
            {(d.type === 'datepicker') && (
              <div className="bg_blue p-3">
                <h6 className="font-weight-bold mt-4">
                  {d.label}
                  {d.isRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
                </h6> 
                <DatePicker
                            selected={formData[d.field] ? new Date(formData[d.field]) : null}
                            onChange={(date) => {
                              const localDate = date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().split('T')[0] : null;
                              setFormData({ ...formData, [d.field]: localDate });
                            }}
                            minDate={d.min}
                            dateFormat={ d.dateFormat === "YYYY-MM-DD" ? "yyyy/MM/dd" : d.dateFormat === "DD-MM-YYYY" ? "dd/MM/YYYY" : "MM/dd/yyyy"}
                            className="dateformcontrol"
                            maxDate={d.max}
                          />

                <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: 'red' }}>
                  {requiredMessage[d.field] || ''}
                </p>
              </div>
            )}
            {(d.inputType === 'date') && (
              <div className="bg_blue p-3">
                <h6 className="font-weight-bold mt-4">
                  {d.label}
                  {d.isRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
                </h6>
                <input
                  type="date"
                  min={d.min}
                  max={d.max}
                  onFocus={() => removeErr(d.field)}
                  value={formData[d.field] ? moment(formData[d.field]).format('YYYY-MM-DD') : ''}  
                  onChange={(e) =>
                    setFormData((prevFormData: any) => ({
                      ...prevFormData,
                      [d.field]: e.target.value,
                    }))
                  }
                  className="phone-form-control"
                  placeholder=""
                />
                <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: 'red' }}>
                    {requiredMessage[d.field] || ''}
                  </p>
              </div>
            )}
            {(d.inputType === 'datetime-local') && (
                <div className="bg_blue p-3">
                  <h6 className="font-weight-bold mt-4">
                    {d.label}
                    {d.isRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
                  </h6>
                  {/* <DatePicker
                    selected={formData[d.field] ? new Date(formData[d.field]) : null}
                    onChange={(date) => setFormData({ ...formData, [d.field]: date ? date.toISOString() : null })}
                    dateFormat="MM/dd/yyyy h:mm A"  // Display format for date and time
                    showTimeSelect  // Enables time selection
                    timeFormat="h:mm A"  // Time format
                    className="form-control form-control-md"
                    maxDate={new Date()}
                    
                  /> */}
                  <input
                  type="datetime-local"
                  min={d.min}
                  max={d.max}
                  onFocus={() => removeErr(d.field)}
                  value={formData[d.field] 
                    ? new Date(formData[d.field]).toISOString().slice(0, 16)  // Format for datetime-local (YYYY-MM-DDTHH:MM)
                    : ''}
                  onChange={(e) =>
                    setFormData((prevFormData:any) => ({
                      ...prevFormData,
                      [d.field]: e.target.value,
                    }))
                  }
                  className="phone-form-control"
                  placeholder=""
                />
                <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: 'red' }}>
                  {requiredMessage[d.field] || ''}
                </p>
                </div>
            )}
            {d.type === 'checkbox' && (
              <div className="bg_blue p-3">
                <h6 className="font-weight-bold mt-4">
                  {d.label}
                  {d.isRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
                </h6>
                <Select
                  onFocus={() => removeErr(d.field)}
                  value={
                    d.choices.filter((option:any) =>
                      (formData[d.field] || []).some((selected:any) => selected.Key === option.Key)
                    )
                  }
                  isMulti
                  options={d.choices}
                  getOptionLabel={(option:any) => option.Value}
                  getOptionValue={(option:any) => option.Key}
                  onChange={(selectedOptions:any) => handleSelectChange(selectedOptions, d.field, d)}
                />
                <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: 'red' }}>
                  {requiredMessage[d.field] || ''}
                </p>
              </div>
            )}
            {d.type === 'tagbox' && (
              <div className="bg_blue p-3">
                <h6 className="font-weight-bold mt-4">
                  {d.label}
                  {d.isRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
                </h6>
                <Select
                  onFocus={() => removeErr(d.field)}
                  value={
                    d.choices.filter((option:any) =>
                      (formData[d.field] || []).some((selected:any) => selected.Key === option.Key)
                    )
                  }
                  isMulti
                  options={d.choices}
                  getOptionLabel={(option:any) => option.Value}
                  getOptionValue={(option:any) => option.Key}
                  onChange={(selectedOptions:any) => handleSelectChange(selectedOptions, d.field, d)}
                />
                <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: 'red' }}>
                  {requiredMessage[d.field] || ''}
                </p>
              </div>
            )}
            {d.type === 'dropdown' && (
              <div className="bg_blue p-3">
                <h6 className="font-weight-bold mt-4">
                  {d.label}
                  {d.isRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
                </h6>
                <Select
                  onFocus={() => removeErr(d.field)}
                  value={d.choices.find((option:any) => option.Key === formData[d.field]?.Key)}
                  options={d.choices}
                  getOptionLabel={(option:any) => option.Value}
                  getOptionValue={(option:any) => option.Key}
                  onChange={(selectedOption:any) => handleSelectChange(selectedOption, d.field, d)}
                  isClearable={true}
                />
                <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: 'red' }}>
                  {requiredMessage[d.field] || ''}
                </p>
              </div>
            )}
            {d.type === 'monthfield' && (
              <div className="bg_blue p-3">
                <h6 className="font-weight-bold mt-4">
                  {d.label}
                  {d.isRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
                </h6>
                <Select
                  onFocus={() => removeErr(d.field)}
                  value={(d.monthOptions || MONTH_ARRAY).find((option:any) => option.value === formData[d.field]?.value)}
                  options={d.monthOptions || MONTH_ARRAY}
                  getOptionLabel={(option:any) => option.text}
                  getOptionValue={(option:any) => option.value}
                  onChange={(selectedOption:any) => handleSelectChange(selectedOption, d.field, d)}
                  isClearable={true}
                />
                <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: 'red' }}>
                  {requiredMessage[d.field] || ''}
                </p>
              </div>
            )}
            {d.type === 'boolean' && (
               <div className="bg_blue p-3">
               <h6 className="font-weight-bold mt-4">
                 {d.label} {d.isRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
               </h6>
               <Select
                 onFocus={() => removeErr(d.field)}
                 value={booleanOptions.find(option => option.Key === formData[d.field]?.Key)}
                 onChange={(selectedOption:any) => handleSelectChange(selectedOption, d.field, d)}
                 options={booleanOptions}
                 getOptionLabel={(option:any) => option.Value}
                 getOptionValue={(option:any) => option.Key}
                 isMulti={false}
                 isClearable={true}
               />
               <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: 'red' }}>
                 {requiredMessage[d.field] || ''}
               </p>
             </div>
            )}
            {d.field.includes('.country') && (
                <div className="bg_blue p-3">
                  <h6 className="font-weight-bold mt-4">
                    {d.label}
                    {d.countryIsRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
                  </h6>
                  <Select
                    onFocus={() => removeErr(d.field)}
                    value={
                      listOfCountry.find((option: any) => option.Key === formData[d.field]?.Key) || 
                      formData[d.field] // fallback to formData[d.field] if no match
                    }
                    onChange={(selectedOption: any) => handleSelectChange(selectedOption, d.field, d)}
                    options={listOfCountry}
                    getOptionLabel={(option: any) => option.Value}
                    getOptionValue={(option: any) => option.Key}
                    isMulti={false}
                    isClearable={true}
                  />
                  <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: 'red' }}>
                    {requiredMessage[d.field] || ''}
                  </p>
                </div>
            )}

            {d.field.includes('.state') && (
              <div className="bg_blue p-3">
                <h6 className="font-weight-bold mt-4">
                  {d.label}
                  {d.stateIsRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
                </h6>
                <input
                  type="text"
                  onFocus={() => removeErr(d.field)}
                  value={formData[d.field] || ''}
                  onChange={(e) =>
                    setFormData((prevFormData:any) => ({
                      ...prevFormData,
                      [d.field]: e.target.value,
                    }))
                  }
                  className="form-control"
                  placeholder=""
                />
                <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: 'red' }}>
                  {requiredMessage[d.field] || ''}
                </p>
              </div>
            )}        
            {d.field.includes('.city') && (
              <div className="bg_blue p-3">
                <h6 className="font-weight-bold mt-4">
                  {d.label}
                  {d.cityIsRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
                </h6>
                <input
                  type="text"
                  onFocus={() => removeErr(d.field)}
                  value={formData[d.field] || ''}
                  onChange={(e) =>
                    setFormData((prevFormData:any) => ({
                      ...prevFormData,
                      [d.field]: e.target.value,
                    }))
                  }
                  className="phone-form-control"
                  placeholder=""
                />
                <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: 'red' }}>
                  {requiredMessage[d.field] || ''}
                </p>
              </div>
            )}            
            {d.field.includes('.postalCode') && (
              <div className="bg_blue p-3">
                <h6 className="font-weight-bold mt-4">
                  {d.label}
                  {d.postalCodeIsRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
                </h6>
                <input
                  type="text"
                  onFocus={() => removeErr(d.field)}
                  value={formData[d.field] || ''}
                  onChange={(e) =>
                    setFormData((prevFormData:any) => ({
                      ...prevFormData,
                      [d.field]: e.target.value,
                    }))
                  }
                  className="phone-form-control"
                  placeholder=""
                />
                <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: 'red' }}>
                  {requiredMessage[d.field] || ''}
                </p>
              </div>
            )}
            {d.type === 'file' && (
              <div className="bg_blue p-3">
                <h6 className="font-weight-bold mt-4">{d.label}</h6>
                {formData[d.field] && renderFiles(formData[d.field])}
              </div>
            )}
            {d.type === 'radiogroup' && (
              <div className="bg_blue p-3">
                <h6 className="font-weight-bold mt-4">
                  {d.label}
                  {d.isRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
                </h6>
                <div>
                  {d.choices.map((option:any, index:any) => (
                    <label key={index}>
                      <input
                        onFocus={() => removeErr(d.field)}
                        type="radio"
                        className="mx-2"
                        value={option.Key}
                        checked={formData[d.field] === option.Key}
                        onChange={() =>
                          setFormData((prevFormData:any) => ({
                            ...prevFormData,
                            [d.field]: option.Key,
                          }))
                        }
                      />
                      {option.Value}
                    </label>
                  ))}
                </div>
                <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: 'red' }}>
                  {requiredMessage[d.field] || ''}
                </p>
              </div>
            )}
            {d.type === 'emailfield' && (
              <div className="bg_blue p-3">
                <h6 className="font-weight-bold mt-4">
                  {d.label}
                  {d.isRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
                </h6>
                <input
                  type="email"
                  onFocus={() => removeErr(d.field)}
                  value={formData[d.field] || ''}
                  onChange={(e) =>
                    setFormData((prevFormData:any) => ({
                      ...prevFormData,
                      [d.field]: e.target.value,
                    }))
                  }
                  className="phone-form-control"
                  placeholder=""
                />
                <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: 'red' }}>
                  {requiredMessage[d.field] || ''}
                </p>
              </div>
            )}
            {d.type === 'text' && !d.inputType && (
              <div className="bg_blue p-3">
                <h6 className="font-weight-bold mt-4">
                  {d.label}
                  {d.isRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
                </h6>
                <input
                  type="text"
                  onFocus={() => removeErr(d.field)}
                  value={formData[d.field] || ''}
                  onChange={(e) =>
                    setFormData((prevFormData:any) => ({
                      ...prevFormData,
                      [d.field]: e.target.value,
                    }))
                  }
                  className="phone-form-control"
                  placeholder=""
                />
                <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: 'red' }}>
                  {requiredMessage[d.field] || ''}
                </p>
              </div>
            )}
            {d.type === 'comment' && (
              <div className="bg_blue p-3">
                <h6 className="font-weight-bold mt-4">
                  {d.label}
                  {d.isRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
                </h6>
                <input
                  type="text"
                  onFocus={() => removeErr(d.field)}
                  value={formData[d.field] || ''}
                  onChange={(e) =>
                    setFormData((prevFormData:any) => ({
                      ...prevFormData,
                      [d.field]: e.target.value,
                    }))
                  }
                  className="phone-form-control"
                  placeholder=""
                />
                <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: 'red' }}>
                  {requiredMessage[d.field] || ''}
                </p>
              </div>
            )}
            {d.type === "phonenumber" && (
                    <div key={i}>
                        <h6 className="font-weight-bold mt-4">
                            {d.label} <span className="mandatory" style={{ color: "red" }}>{d.isRequired ? "*" : ""}</span>
                        </h6>
                        <PhoneInput
                            ref={phoneInputRef}
                            PhoneName={d.field}
                            PhoneNumber={formData[d.field]}
                            onInput={change}
                            blurMethod={() => handleBlur(d.field)}
                            onFocus={() => removeErr(d.field)}
                        />
                        <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: "red" }}>
                            {errMessage[d.field] || ''}
                        </p>
                        <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: "red" }}>
                            {requiredMessage[d.field] || ''}
                        </p>
                    </div>
            )}
            {(d.inputType === 'week') && (
                <div className="bg_blue p-3">
                  <h6 className="font-weight-bold mt-4">
                    {d.label}
                    {d.isRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
                  </h6>
                  <input
                  type="week"
                  onFocus={() => removeErr(d.field)}
                  value={formData[d.field] || ''}
                  min={d.min}
                  max={d.max}
                  onChange={(e) =>
                    setFormData((prevFormData:any) => ({
                      ...prevFormData,
                      [d.field]: e.target.value,
                    }))
                  }
                  className="phone-form-control"
                  placeholder=""
                />
                <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: 'red' }}>
                  {requiredMessage[d.field] || ''}
                </p>
                </div>
            )}
            {(d.inputType === 'color') && (
                <div className="bg_blue p-3">
                  <h6 className="font-weight-bold mt-4">
                    {d.label}
                    {d.isRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
                  </h6>
                  <input
                  type="color"
                  onFocus={() => removeErr(d.field)}
                  value={formData[d.field] || ''}
                  onChange={(e) =>
                    setFormData((prevFormData:any) => ({
                      ...prevFormData,
                      [d.field]: e.target.value,
                    }))
                  }
                  className="phone-form-control"
                  placeholder=""
                />
                <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: 'red' }}>
                  {requiredMessage[d.field] || ''}
                </p>
                </div>
            )}
            {(d.inputType === 'range') && (
                <div className="bg_blue p-3">
                  <h6 className="font-weight-bold mt-4">
                    {d.label}
                    {d.isRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
                  </h6>
                  <input
                  onFocus={() => removeErr(d.field)}
                  type="range"
                  min={d.min}
                  max={d.max}
                  value={formData[d.field] || ''}
                  onChange={(e) =>
                    setFormData((prevFormData:any) => ({
                      ...prevFormData,
                      [d.field]: e.target.value,
                    }))
                  }
                  className="phone-form-control"
                  placeholder=""
                />
                <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: 'red' }}>
                  {requiredMessage[d.field] || ''}
                </p>
                </div>
            )}
            {(d.inputType === 'month') && (
                <div className="bg_blue p-3">
                  <h6 className="font-weight-bold mt-4">
                    {d.label}
                    {d.isRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
                  </h6>
                  <input
                  type="month"
                  min={d.min}
                  max={d.max}
                  value={formData[d.field] || ''}
                  onFocus={() => removeErr(d.field)}
                  onChange={(e) =>
                    setFormData((prevFormData:any) => ({
                      ...prevFormData,
                      [d.field]: e.target.value,
                    }))
                  }
                  className="phone-form-control"
                  placeholder=""
                />
                <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: 'red' }}>
                  {requiredMessage[d.field] || ''}
                </p>
                </div>
            )}
            {(d.inputType === 'time') && (
                <div className="bg_blue p-3">
                  <h6 className="font-weight-bold mt-4">
                    {d.label}
                    {d.isRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
                  </h6>
                  <input
                  type="time"
                  min={d.min}
                  max={d.max}
                  value={formData[d.field] || ''}
                  onFocus={() => removeErr(d.field)}
                  onChange={(e) =>
                    setFormData((prevFormData:any) => ({
                      ...prevFormData,
                      [d.field]: e.target.value,
                    }))
                  }
                  className="phone-form-control"
                  placeholder=""
                />
                <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: 'red' }}>
                  {requiredMessage[d.field] || ''}
                </p>
                </div>
            )}
            {(d.inputType === 'number') && (
                <div className="bg_blue p-3">
                  <h6 className="font-weight-bold mt-4">
                    {d.label}
                    {d.isRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
                  </h6>
                  <input
                  min={d.min}
                  max={d.max}
                  type="number"
                  value={formData[d.field] || ''}
                  onFocus={() => removeErr(d.field)}
                  onChange={(e) =>
                    setFormData((prevFormData:any) => ({
                      ...prevFormData,
                      [d.field]: e.target.value,
                    }))
                  }
                  className="phone-form-control"
                  placeholder=""
                />
                <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: 'red' }}>
                  {requiredMessage[d.field] || ''}
                </p>
                </div>
            )}
            {(d.inputType === 'email') && (
                <div className="bg_blue p-3">
                  <h6 className="font-weight-bold mt-4">
                    {d.label}
                    {d.isRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
                  </h6>
                  <input
                  type="email"
                  onFocus={() => removeErr(d.field)}
                  value={formData[d.field] || ''}
                  onChange={(e) =>
                    setFormData((prevFormData:any) => ({
                      ...prevFormData,
                      [d.field]: e.target.value,
                    }))
                  }
                  className="phone-form-control"
                  placeholder={d.placeholder}
                />
                <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: 'red' }}>
                  {requiredMessage[d.field] || ''}
                </p>
                </div>
            )}
            {(d.inputType === 'url') && (
                <div className="bg_blue p-3">
                  <h6 className="font-weight-bold mt-4">
                    {d.label}
                    {d.isRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
                  </h6>
                  <input
                  type="text"
                  onFocus={() => removeErr(d.field)}
                  value={formData[d.field] || ''}
                  onChange={(e) =>
                    setFormData((prevFormData:any) => ({
                      ...prevFormData,
                      [d.field]: e.target.value,
                    }))
                  }
                  className="phone-form-control"
                  placeholder={d.placeholder}
                />
                <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: 'red' }}>
                  {requiredMessage[d.field] || ''}
                </p>
                </div>
            )}
            {(d.inputType === 'tel') && (
                <div className="bg_blue p-3">
                  <h6 className="font-weight-bold mt-4">
                    {d.label}
                    {d.isRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
                  </h6>
                  <input
                  type="tel"
                  onFocus={() => removeErr(d.field)}
                  value={formData[d.field] || ''}
                  onChange={(e) =>
                    setFormData((prevFormData:any) => ({
                      ...prevFormData,
                      [d.field]: e.target.value,
                    }))
                  }
                  className="phone-form-control"
                  placeholder={d.placeholder}
                />
                <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: 'red' }}>
                  {requiredMessage[d.field] || ''}
                </p>
                </div>
            )}
            {(d.inputType === 'password') && (
                <div className="bg_blue p-3">
                  <h6 className="font-weight-bold mt-4">
                    {d.label}
                    {d.isRequired && <span className="mandatory" style={{ color: 'red' }}>*</span>}
                  </h6>
                  <input
                  type="password"
                  onFocus={() => removeErr(d.field)}
                  value={formData[d.field] || ''}
                  onChange={(e) =>
                    setFormData((prevFormData:any) => ({
                      ...prevFormData,
                      [d.field]: e.target.value,
                    }))
                  }
                  className="phone-form-control"
                  placeholder={d.placeholder}
                />
                <p className="field-error font-weight-bold" style={{ opacity: 0.6, color: 'red' }}>
                  {requiredMessage[d.field] || ''}
                </p>
                </div>
            )}
          </div>
          )}
        </div>
      ))}
    </div>
  );
});

export default EditRecord;
